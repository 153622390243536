<template>
  <div class="deviceMQBGView">
    <div class="deviceMQTab">
      <div class="dateBGView">
        <div class="dateTitle" @click="dateChange(-1)">前一天</div>
        <div class="dateHead">
          <el-date-picker
              v-if="timeType=='day'"
              value-format="timestamp"
              v-model="startTime"
              type="date"
              placeholder="选择日期"
          >
          </el-date-picker>
          <el-time-select
              v-if="timeType=='hour'"
              placeholder="起始时间"
              v-model="hourTime"
              :picker-options="{
                start: '00:00',
                step: '00:1',
                end: '24:00'
              }"
          >
          </el-time-select>

          <div class="dateStr">{{ dateStr }}</div>
        </div>

        <div class="dateTitle" @click="dateChange(1)">后一天</div>
      </div>
      <el-tabs :stretch="true" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane v-for="(item) in tabList"
                     :disabled="item.router==''"
                     :name="item.title"
                     :key="item.title">
        <span slot="label">
          <h-stack>
            <div style="float: left;padding: 0px 5px 0px 5px">{{ item.title }}</div>
            <el-badge v-if="item.number>0" :value="item.number" size="mini" class="item"></el-badge>
          </h-stack>
        </span>
          <router-link :to="item.router"></router-link>
          <router-view
              :id="loadingDivId"
              style="max-height: calc(100% - 60px);"
              v-if="item.show&&!loading"
              v-on:loadingChange="loadingChange"
              v-bind:view-style="viewType"
              v-bind:query="item.query"
              v-bind:show="item.show&&!loading"
              v-bind:search-info="fillterConfig"
              v-on:stopRefresh="stopRefresh"
              v-on:updateNumber="updateNumber"
          ></router-view>
        </el-tab-pane>
      </el-tabs>
    </div>
    <fillter-bar
        :fillter-key-info="{'viewType':'', 'timeType':'', 'time':'', 'station':'', 'device':''}"
        :fillter-info="fillterInfo"
        @delayRefresh="delayRefresh"
        @refresh="refresh"
        @showFillter="showFillter"
        @startRefresh="startRefresh"

    >
    </fillter-bar>

    <el-drawer
        size="70%"
        class="fillterView"
        :with-header="false"
        :visible.sync="showFillterView"
        direction="rtl"
    >
      <fillter-view ref="fillterView" :view-config=" {
        viewStyle:'',
        timeStyle:'',
        selectTime: '',
        selectStation: '',
        }" @updateSearch="updateSearch"
      ></fillter-view>
    </el-drawer>

  </div>
</template>

<script>

import HStack from '@/components/hStack/hStack'
import Z2View from '@/views/deviceMQ/Z2View/Z2View'
import FillterBar from '@/views/deviceMQ/fillterBar/fillterBar'
import FillterView from '@/views/deviceMQ/fillterView/fillterView'
import DeviceMsgBox from '@/views/deviceMQ/deviceMsgBox/deviceMsgBox'
import deviceMQMixin from '@/views/deviceMQ/MQmixin/deviceMQMixin'

export default {
  name: 'device-Station-MQ',
  components: { DeviceMsgBox, FillterView, FillterBar, Z2View, HStack },
  mixins: [deviceMQMixin],
  props: {},
  data() {
    return {
      loadingDivId: 'device-Station-MQ',
      viewType: 'list', // list 列表  rssi 图表
      timeType: 'day', // day 按天  hour 按小时
      startTime: new Date(new Date(new Date().toLocaleDateString()).getTime()).getTime(),
      hourTime: '00:00',
      query: {}
    }
  },
  watch: {
    hourTime: {
      handler(value) {
        let str = (this.formatDayDate(this.startTime) + ' ' + value).replace(/-/g,'/')
        let timestamp = new Date(str).getTime();
        this.startTime = timestamp;
      },
      immediate: true,
      deep: true
    },
    startTime: {
      handler(value) {
        if (this.loading == true) {
          return
        }
        this.getTabList().then(res => {
          if (res.result == false) {
            return
          }
          this.getFillterInfo()
        })
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    dateStr: function() {
      function getWeek(date) {
        let week
        if (date.getDay() == 0) week = '星期日'
        if (date.getDay() == 1) week = '星期一'
        if (date.getDay() == 2) week = '星期二'
        if (date.getDay() == 3) week = '星期三'
        if (date.getDay() == 4) week = '星期四'
        if (date.getDay() == 5) week = '星期五'
        if (date.getDay() == 6) week = '星期六'
        return week
      }

      let str = ''

      let time = this.startTime
      str = this.formatDayDate(time) + ' ' + (this.timeType == 'day' ? '' : this.hourTime)
      return str + ' (' + getWeek(new Date(str)) + ')'
    }
  },
  mounted() {
    this.query = JSON.parse(JSON.stringify(this.$route.query))
  },
  methods: {
    afterUpdateing: function() {
      for (let i = 0; i < this.fillterInfo.fillterList.length; i++) {
        let info = this.fillterInfo.fillterList[i]
        if (info.type == 'timeType') {
          this.timeType = info.id
        } else if (info.type == 'viewType') {
          this.viewType = info.id
        }
      }
    },
    // 前一天/后一天
    dateChange: function(value) {
      this.startTime = this.startTime + 1 * 24 * 3600 * 1000 * value
    },
    updateTabList: function(callBack) {
      let query = JSON.parse(JSON.stringify(this.query))
      let startTime = this.startTime
      let endTime = this.startTime + 1 * 24 * 3600 * 1000
      query['startTime'] = startTime
      query['endTime'] = endTime
      this.$api.deviceMQ.getDeviceDetail(query).then(res => {
        let dataList = []
        if (res.code != 200) {
          callBack({ dataList: dataList })
          return
        }
        let router = ''
        if (query.deviceType == '1') {
          router = '/device-Station-MQ/Z2View'
        } else if (query.deviceType == '2') {
          router = '/device-Station-MQ/Z3View'
        } else if (query.deviceType == '3') {
          router = '/device-Station-MQ/Z4View'
        } else if (query.deviceType == '4') {
          router = '/device-Station-MQ/Z5View'
        } else {
          callBack({ dataList: [] })
          return
        }

        let titleKey = ''
        if (query.deviceType == '1' || query.deviceType == '2' || query.deviceType == '3' || query.deviceType == '4') {
          titleKey = 'baseMac'
        }

        for (let i = 0; i < res.data.length; i++) {
          let data = res.data[i]
          if (typeof data['countList'] == 'undefined' || data['countList'] == null) {
            continue;
          }
          let countList = data['countList']
          for (let j = 0; j < countList.length; j++) {
            let dataInfo = countList[j];
            let title = dataInfo[titleKey]
            let number = 0
            if (typeof dataInfo['num'] != 'undefined' && dataInfo['num'] != null) {
              number = dataInfo['num']
            }
            let itemInfo = {
              title: title,
              number: number,
              router: router,
              show: false,
              query: {
                includeBaseStationMac: title,
                startTime: startTime,
                endTime: endTime,
                time: 1 * 24 * 3600 // 将时间定为一天
              }
            }
            dataList.push(itemInfo)
          }


        }
        dataList.sort((a, b) => {
          return b.number - a.number
        })
        callBack({ dataList: dataList })

      }).catch(() => {
        callBack({ dataList: [] })
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.deviceMQBGView {
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;

  .fillterView {
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .deviceMQTab {
    width: 100%;
    height: calc(100% - 100px);

    .dateBGView {
      width: 100%;
      height: 50px;
      background-color: white;
      border-bottom: 1px gainsboro solid;

      .dateTitle {
        float: left;
        width: calc(50% - 110px);
        height: 50px;
        line-height: 50px;
        text-align: center;
      }

      .dateHead {
        float: left;
        width: 220px;
        height: 50px;

        .dateStr {
          width: 100%;
          height: 50px;
          line-height: 50px;
          text-align: center;
          color: red;
          z-index: 100;
          transform: translate(0px, -40px);
          pointer-events: none;
          background-color: white;
          border-bottom: 1px gainsboro solid;
        }

      }

    }


    .el-tabs {
      height: 100%;

      .el-tabs__content {


        .el-tab-pane {
          height: 100%;
        }
      }
    }
  }
}


</style>
