<template>
  <el-dialog
      :append-to-body="true"
      :modal-append-to-body="false"
      title=""
      :visible.sync="visible"
      width="80%"
      class="deviceMsgBoxBGView"
      center
      :before-close="handleClose"
  >
    <div class="contentView">

      <div class="cellView">

        <div class="title">
          {{ title }}
        </div>
        <div class="title">
          {{ '设备:' + deviceMac }}
        </div>
        <div class="title bottom">
          {{ '基站:' + stationMac }}
        </div>
        <div class="title bottom" @click="deviceHistory">
          设备历史记录
        </div>
        <div class="title bottom" @click="stationHistory">
          基站历史记录
        </div>
        <div class="title bottom">
          设备录入
        </div>
        <div class="title bottom">
          基站录入
        </div>
        <div class="title bottom" @click="fillterDevice">
          过滤此设备
        </div>
        <div class="title" @click="fillterStation">
          过滤此基站
        </div>
      </div>
    </div>
  </el-dialog>

</template>

<script>
export default {
  name: 'deviceMsgBox',
  computed: {
    title: function() {
      let title = '设备'
      if (typeof this.deviceInfo['title'] != 'undefined' && this.deviceInfo['title'] != null) {
        title = this.deviceInfo['title']
      }
      return title
    },
    deviceMac: function() {
      let key = ''
      let mac = ''
      if (this.title == 'Z2'
          || this.title == 'Z3'
          || this.title == 'Z4'
          || this.title == 'Z5') {
        key = 'macAddr'
      }
      if (typeof this.deviceInfo[key] != 'undefined' && this.deviceInfo[key] != null) {
        mac = this.deviceInfo[key]
      }
      return mac
    },
    stationMac: function() {
      let key = ''
      let mac = ''
      if (this.title == 'Z2'
          || this.title == 'Z3'
          || this.title == 'Z4'
          || this.title == 'Z5') {
        key = 'baseMac'
      }
      if (typeof this.deviceInfo[key] != 'undefined' && this.deviceInfo[key] != null) {
        mac = this.deviceInfo[key]
      }
      return mac
    }
  },
  props: {
    deviceInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    deviceInfo: {
      handler(value) {

      },
      immediate: true,
      deep: true
    },
    dialogVisible: {
      handler(value) {
        this.visible = value
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    stationHistory:function() {
      this.$emit('stationHistory', { mac: this.stationMac })
    },
    deviceHistory: function() {
      this.$emit('deviceHistory', { mac: this.deviceMac })
    },
    sure: function() {
      this.$emit('deviceMsgBoxBGViewClose', { dialogVisible: false, result: true })
    },
    close: function() {
      this.$emit('deviceMsgBoxBGViewClose', { dialogVisible: false, result: false })
    },
    handleClose: function() {
      this.close()
    },
    fillterDevice: function() {
      let info = {
        title: '设备' + '排除' + ' ' + this.deviceMac,
        id: this.deviceMac,
        type: 'device',
        status: 2
      }
      this.$emit('fillterAction', info)
    },
    fillterStation: function() {
      let info = {
        title: '基站' + '排除' + ' ' + this.stationMac,
        id: this.stationMac,
        type: 'station',
        status: 2
      }
      this.$emit('fillterAction', info)
    }
  }
}
</script>

<style lang="scss" scoped>

.deviceMsgBoxBGView {
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  position: absolute;
  background-color: transparent;

  .contentView {
    width: 100%;
    border: 1px gainsboro solid;

    .cellView {
      width: 100%;

      .bottom {
        border-bottom: 1px gainsboro solid;

      }

      .title {
        width: 100%;
        line-height: 40px;
        font-size: 16px;
        color: gray;
        text-align: center;
        word-wrap: break-word;

      }


    }

  }

}

</style>
